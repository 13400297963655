import React from "react";
import PropTypes from "prop-types";
import EventItem from "../../../components/event";
import SectionTitle from "../../../components/title";

const EventArea = ({ data }) => {
    return (
        <section className="upcoming-gaming-section pb-16 md:pb-24">
            <div className="container">
                {data?.section_title && (
                    <div className="section-title mb-15">
                        <SectionTitle
                            heading={data?.section_title.heading}
                            {...data.section_title}
                        />
                    </div>
                )}
                {data?.items &&
                    data?.items.map((item) => (
                        <EventItem
                            key={item.id}
                            title={item?.title}
                            eventTopic={item?.eventTopic}
                            date={item?.date}
                            slug={item?.slug}
                            video_link={item?.liveStreaming?.link}
                            teamImage1={item?.teams[0]?.logo.src}
                            teamImage2={item?.teams[1]?.logo.src}
                            tesmSlug1={item?.teams[0]?.slug}
                            tesmSlug2={item?.teams[1]?.slug}
                        />
                    ))}
            </div>
        </section>
    );
};
EventArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
    }),
};
export default EventArea;
