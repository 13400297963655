import React from "react";
import PropTypes from "prop-types";
import SEO from "@components/seo";
import Layout from "@layout";
import PageBreadcrumb from "../components/pagebreadcrumb";
import EventArea from "../container/all-event/event";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import FunfactArea from "../container/home/funfact";

const EventPage = ({ data, location, pageContext }) => {
    console.log("DATA", data);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page.content || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Events Page" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="UPCOMING Event"
            />
            <EventArea
                data={{
                    items: data?.allEvent?.nodes,
                }}
            />

            <FunfactArea
                className=" -mt-10"
                data={content["funfact-section"]}
            />
        </Layout>
    );
};

EventPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allEvent: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query eventPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "eventPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
            }
        }
        allEvent(sort: { order: DESC, fields: date }) {
            nodes {
                ...Events
            }
        }
    }
`;

export default EventPage;
